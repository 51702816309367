import "./digitalized.css";
import React, { useState, useEffect } from "react";
function Digitalized() {
  const [sizeWebView, setWindowWidth] = useState(() => {
    const savedSizeWebView = localStorage.getItem("savedSizeWebView");
    return savedSizeWebView ? savedSizeWebView : window.innerWidth;
  });
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    localStorage.setItem("savedSizeWebView", sizeWebView);
  }, [sizeWebView]);
  return (
    <div className="sectionDigitStyle">
      <div className="imageHeaderDigit">
        <img
          src={
            sizeWebView < 801
              ? "https://tbhwebsite.s3.me-south-1.amazonaws.com/digitalizedImageGroupedMobile.webp"
              : "https://tbhwebsite.s3.me-south-1.amazonaws.com/digitalizedImageGrouped.webp"
          }
          alt={`imageDigitalized`}
          className="imageStyleDigit"
        />
        <div className="divAppStores">
          <img
            onClick={() => {
              window.open(
                "https://apps.apple.com/eg/app/the-business-hub/id6453887394",
                "_blank"
              );
            }}
            className="appleIconStyleHeaders"
            src={
              "https://tbhwebsite.s3.me-south-1.amazonaws.com/appleStoreIcon.webp"
            }
            alt="apple"
          />
          <img
            onClick={() => {
              window.open(
                "https://play.google.com/store/apps/details?id=space.thebusinesshub.android&pcampaignid=web_share",
                "_blank"
              );
            }}
            className="googleIconStyleHeaders"
            src={
              "https://tbhwebsite.s3.me-south-1.amazonaws.com/googlePlayStoreIcon.webp"
            }
            alt="google"
          />
        </div>
      </div>
    </div>
  );
}

export default Digitalized;
