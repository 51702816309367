import React, { useState } from "react";
import "./carousel.css";

function Carousel({ data }) {
  const [slide, setSlide] = useState(0);

  const nextSlide = () => {
    setSlide(slide === data.length - 1 ? 0 : slide + 1);
  };

  const prevSlide = () => {
    setSlide(slide === 0 ? data.length - 1 : slide - 1);
  };
  return (
    <div className="carousel">
      <img
        className="arrow arrow-left"
        src={"https://tbhwebsite.s3.me-south-1.amazonaws.com/leftWhitIcon.webp"}
        alt="leftSlideCarousel"
        onClick={prevSlide}
      />
      {data.map((singleItem, index) => {
        return (
          <img
            className={slide === index ? "slide" : "slide slide-hidden"}
            key={index}
            src={singleItem.src}
            alt={singleItem.alt}
          />
        );
      })}
      <img
        className="arrow arrow-right"
        src={
          "https://tbhwebsite.s3.me-south-1.amazonaws.com/rightWhitIcon.webp"
        }
        onClick={nextSlide}
        alt="rightSlideCarousel"
      />
      <span className="indicators">
        {data.map((_, idx) => {
          return (
            <button
              key={idx}
              className={
                slide === idx ? "indicator" : "indicator indicator-inactive"
              }
              onClick={() => setSlide(idx)}
            ></button>
          );
        })}
      </span>
    </div>
  );
}

export default Carousel;
