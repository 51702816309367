import "./App.css";
import MainPage from "./components/main";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PolicyPrivacy from "./components/policy/policy";

function App() {
  // Clear local storage on page load
  localStorage.clear();
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />}></Route>
          {/* <Route path="/ho" element={<About />}></Route> */}
          <Route path="/Policy" element={<PolicyPrivacy />}></Route>
        </Routes>
        {/* <Footer /> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
