import React, { useState, useEffect } from "react";
import "./mainHeader.css";
import loaderImage from "../../../imgs/loaderGifThreeWeb.gif";
import tbhLoaderMobileVersion from "../../../imgs/tbhLaoderMobileVersion.gif";
function MainHeader() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentDotsIndex, setCurrentDotsIndex] = useState(0);
  const [sizeWebView, setWindowWidth] = useState(() => {
    const savedSizeWebView = localStorage.getItem("savedSizeWebView");
    return savedSizeWebView ? savedSizeWebView : window.innerWidth;
  });
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  var imagesHeadersList = [
    {
      mobile:
        "https://tbhwebsite.s3.me-south-1.amazonaws.com/latestTrialFirstHeaderMobileVersionTRIALPX.webp",
      web: "https://tbhwebsite.s3.me-south-1.amazonaws.com/Join+the+Movement.webp",
    },
    {
      mobile:
        "https://tbhwebsite.s3.me-south-1.amazonaws.com/latestTrialSecondHeaderMobileVersionTRIALPX.webp",
      web: "https://tbhwebsite.s3.me-south-1.amazonaws.com/imageWebHeaderAppStore.webp",
    },
    {
      mobile:
        "https://tbhwebsite.s3.me-south-1.amazonaws.com/latestTrialThirdHeaderTESTMobileVersionTRIALPX.webp",
      web: "https://tbhwebsite.s3.me-south-1.amazonaws.com/Meet+Smarter.webp",
    },
    {
      mobile:
        "https://tbhwebsite.s3.me-south-1.amazonaws.com/latestTrialFourthHeaderMobileVersionTRIALPX.webp",
      web: "https://tbhwebsite.s3.me-south-1.amazonaws.com/Number+One+in+Maadi.webp",
    },
    {
      mobile:
        "https://tbhwebsite.s3.me-south-1.amazonaws.com/latestTrialFifthHeaderMobileVersionTRIALPX.webp",
      web: "https://tbhwebsite.s3.me-south-1.amazonaws.com/One+Spot+Left.webp",
    },
    {
      mobile:
        "https://tbhwebsite.s3.me-south-1.amazonaws.com/latestTrialLastHeaderMobileVersionTRIALPX.webp",
      web: "https://tbhwebsite.s3.me-south-1.amazonaws.com/Community+of+Entreprenurs_.webp",
    },
  ];
  const shiftLeft = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + imagesHeadersList.length) % imagesHeadersList.length
    );
    setCurrentDotsIndex(
      (prevIndex) => (prevIndex - 1 + dotsList.length) % dotsList.length
    );
  };

  const shiftRight = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imagesHeadersList.length);
    setCurrentDotsIndex((prevIndex) => (prevIndex + 1) % dotsList.length);
  };
  var dotsList = [
    <div
      className={currentDotsIndex === 0 ? "singleSelectedDot" : "singleDot"}
    ></div>,
    <div
      className={currentDotsIndex === 1 ? "singleSelectedDot" : "singleDot"}
    ></div>,
    <div
      className={currentDotsIndex === 2 ? "singleSelectedDot" : "singleDot"}
    ></div>,
  ];
  // const [isLoaded, setIsLoaded] = useState(false);
  // const [loadedObjects, setLoadedObjects] = useState([]);

  // const handleObjectLoad = (index) => {
  //   setLoadedObjects((prevLoadedObjects) => [...prevLoadedObjects, index]);
  // };

  // const isObjectLoaded = (index) => {
  //   return loadedObjects.includes(index);
  // };
  const [imagesLoaded, setImagesLoaded] = useState(false);

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    localStorage.setItem("savedSizeWebView", sizeWebView);

    // List of image URLs

    // Function to preload images
    const preloadImages = () => {
      const promises = imagesHeadersList.map((url) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = sizeWebView < 575 ? url.mobile : url.web;
          img.onload = resolve;
          img.onerror = reject;
        });
      });

      // Wait for all images to be loaded
      Promise.all(promises).then(() => {
        setImagesLoaded(true);
      });
    };

    // Call the function to preload images
    preloadImages();

    // // Set up the interval (every two seconds)
    const intervalId = setInterval(() => {
      shiftRight();
    }, 3000);

    // // Clean up the interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeWebView]);

  return (
    <div className="headerOneTrial">
      <div className="imageHeader">
        {imagesLoaded ? (
          <img
            src={
              // isObjectLoaded(currentIndex)
              //   ?
              sizeWebView < 575
                ? imagesHeadersList[currentIndex].mobile
                : imagesHeadersList[currentIndex].web
              // : sizeWebView < 575
              // ? tbhLoaderMobileVersion
              // : loaderImage
            }
            // onLoad={() => handleObjectLoad(currentIndex)}
            alt={`trialIMageThree ${imagesHeadersList[currentIndex]}`}
            className="trialImageStyle"
          />
        ) : (
          <img
            style={{ width: "100%" }}
            alt="loader"
            src={sizeWebView < 575 ? tbhLoaderMobileVersion : loaderImage}
          />
        )}
        {/* {isObjectLoaded(currentIndex) && ( */}
        <div className="divCarousel">
          <img
            src={
              "https://tbhwebsite.s3.me-south-1.amazonaws.com/leftIconCarousel.webp"
            }
            onClick={shiftLeft}
            alt="leftIconAlt"
            className="carouselIcon"
          />
          <div className="dotsContainer">
            {dotsList.map((singleDot, index) => (
              <div key={index}>{singleDot}</div>
            ))}
          </div>
          <img
            src={
              "https://tbhwebsite.s3.me-south-1.amazonaws.com/rightIconCarousel.webp"
            }
            onClick={shiftRight}
            alt="rightIconAlr"
            className="carouselIcon"
          />
        </div>
      </div>
    </div>
  );
}

export default MainHeader;
