const companiesDetails = {
  sectionTitle1: "Join our",
  sectionTitle2: "Community!",
  sectionText1: "Connect with passionate professionals,",
  sectionText2: "innovators and entrepreneurs in various fields.",
  btnText: "GET STARTED",
  companiesElements: [
    "https://tbhwebsite.s3.me-south-1.amazonaws.com/betterBrainLabIcon.png",
    "https://tbhwebsite.s3.me-south-1.amazonaws.com/takweenIcon.png",
    "https://tbhwebsite.s3.me-south-1.amazonaws.com/heroIcon.webp",
    "https://tbhwebsite.s3.me-south-1.amazonaws.com/icon199XIcon.webp",
    "https://tbhwebsite.s3.me-south-1.amazonaws.com/tekyaIcon.webp",
    "https://tbhwebsite.s3.me-south-1.amazonaws.com/cottegeIcon.webp",
    "https://tbhwebsite.s3.me-south-1.amazonaws.com/nashwaSalahIcon.webp",
    "https://tbhwebsite.s3.me-south-1.amazonaws.com/vogaziIcon.webp",
    "https://tbhwebsite.s3.me-south-1.amazonaws.com/reLocation.webp",
    "https://tbhwebsite.s3.me-south-1.amazonaws.com/prepHeroIcon.webp",
    "https://tbhwebsite.s3.me-south-1.amazonaws.com/brandHancerIcon.webp",
    "https://tbhwebsite.s3.me-south-1.amazonaws.com/IATFIcon.webp",
    "https://tbhwebsite.s3.me-south-1.amazonaws.com/arabyBrandIcon.webp",
    "https://tbhwebsite.s3.me-south-1.amazonaws.com/mkyCommunicationIcon.webp",
    "https://tbhwebsite.s3.me-south-1.amazonaws.com/weluminateIcon.webp",
    "https://tbhwebsite.s3.me-south-1.amazonaws.com/prepitIcon.webp",
    "https://tbhwebsite.s3.me-south-1.amazonaws.com/swutSolutionsIcon.webp",
    "https://tbhwebsite.s3.me-south-1.amazonaws.com/egyptUsIcon.webp",
  ],
};
// 👇️ named exports
export { companiesDetails };
