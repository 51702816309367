// CustomAlert.js

import React from "react";
import "./CustomAlert.css";
const CustomAlert = ({ title, message, children, onClose }) => {
  return (
    <div>
      <div className="overlay"></div>
      <div className="custom-alert">
        <div className="custom-alert-content">
          <img
            className="gifConfirmStyle"
            src={
              "https://tbhwebsite.s3.me-south-1.amazonaws.com/TBH-ConfirmationTest.gif"
            }
            alt="apple"
          />
          <h1>{title}</h1>
          {children}
          <button onClick={onClose}>OK</button>
        </div>
      </div>
    </div>
  );
};

export default CustomAlert;
