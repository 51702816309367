const solutionsContentText = {
  sectionTitle1: "FLEXIBLE SOLUTIONS",
  sectionText:
    "Explore a seamless blend of flexibility and professionalism within our meticulously designed shared offices and meeting rooms. Tailored to cater to the diverse needs of freelancers, small teams, and growing businesses, our thoughtfully curated spaces provide the ideal environment for meetings, collaboration, and flourishing creativity.",
  solutionsTypes: [
    {
      title: "Office Space in a dynamic community.",
      text: "Fostering collaboration, providing flexibility for professionals and businesses.",
      icon: "https://tbhwebsite.s3.me-south-1.amazonaws.com/officeSpaceIcon.webp",
    },
    {
      title: "Meeting Room with impactful results.",
      text: "A productive space for meetings and discussions, fostering effective communication and decision-making.",
      icon: "https://tbhwebsite.s3.me-south-1.amazonaws.com/officeMeetingIcon.webp",
    },
  ],
  btnTextEng: "LEARN MORE",
};
// 👇️ named exports
export { solutionsContentText };
