import React from "react";
// import ComingSoonPage from "./comingSoon/comingSoon";
import MainHeader from "./headers/mainHeader/mainHeader";
import Solutions from "./solutionsSection/solutions";
import Facilities from "./facilities/facilities";
import Digitalized from "./digitalizedSection/digitalized";
import GetOurAppSection from "./getOurApp/getOurApp";
import Companies from "./companies/companies";
import Contact from "./contactUs/contactUs";
import Footer from "./footer/footer";
import Navbar from "./navbar/navbar";

function MainPage() {
  return (
    <div>
      {" "}
      <Navbar />
      <MainHeader />
      <Solutions />
      <Facilities />
      <Digitalized />
      <GetOurAppSection />
      <Companies />
      <Contact />
      <Footer />
      {/* <ComingSoonPage /> */}
    </div>
  );
}

export default MainPage;
