import React from "react";

export default function PolicyPrivacy(props) {
  return (
    <div
      style={{
        marginTop: "2%",
        marginLeft: "3%",
        marginRight: "3%",
      }}
    >
      {" "}
      <div>
        <div>
          <strong>
            <span
              style={{
                fontSize: "26px",
              }}
            >
              <span data-custom-class="title">
                <bdt className="block-component"></bdt>
                <bdt className="question">PRIVACY POLICY</bdt>
                <bdt className="statement-end-if-in-editor"></bdt>
              </span>
            </span>
          </strong>
        </div>
        <div>
          <br />
        </div>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span>
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              This privacy notice for{" "}
              <bdt class="question">
                Lirten Solutions For Information Technologies
                <bdt class="block-component"></bdt>
                (doing business as <bdt class="question">Lirten Solutions</bdt>)
                <bdt class="statement-end-if-in-editor"></bdt>
              </bdt>{" "}
              (<bdt class="block-component"></bdt>"
              <bdt class="block-component"></bdt>
              <bdt class="question">
                <strong>Lirten Solutions</strong>
              </bdt>
              {/* <bdt class="else-block"></bdt>," "<strong>we</strong>," "
              <strong>us</strong>," or "<strong>our</strong>"
              <bdt class="statement-end-if-in-editor"></bdt> */}
            </span>
            <span data-custom-class="body_text">
              ), describes how and why we might collect, store, use, and/or
              share (<bdt class="block-component"></bdt>"
              <strong>process</strong>"
              <bdt class="statement-end-if-in-editor"></bdt>) your information
              when you use our services (<bdt class="block-component"></bdt>"
              <strong>Services</strong>"
              <bdt class="statement-end-if-in-editor"></bdt>), such as when you:
            </span>
          </span>
        </span>
      </div>
      {/* <h1>TEST</h1> */}
      <span
        style={{
          fontSize: " 15px",
        }}
      >
        <span>
          <span data-custom-class="body_text">
            <span>
              <span data-custom-class="body_text">
                <bdt class="block-component"></bdt>
              </span>
            </span>
          </span>
        </span>
      </span>
      {/* <h1>TEST</h1> */}
      <div>
        <bdt class="block-component">
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span>
                <span data-custom-class="body_text">
                  <span style={{}}>
                    <span data-custom-class="body_text">
                      <bdt class="block-component"></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </bdt>
      </div>
      <ul>
        <li
          style={{
            lineHeight: "1.5",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text">
                Download and use<bdt class="block-component"></bdt> our mobile
                application<bdt class="block-component"></bdt> (
                <bdt class="question">
                  TBH)
                  <span
                    style={{
                      fontSize: " 15px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: " 15px",
                      }}
                    >
                      <span data-custom-class="body_text">
                        <span
                          style={{
                            fontSize: " 15px",
                          }}
                        >
                          <span>
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: " 15px",
                                }}
                              >
                                <span>
                                  <bdt class="statement-end-if-in-editor">
                                    ,
                                  </bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </bdt>
              </span>
              <span data-custom-class="body_text">
                <span
                  style={{
                    fontSize: " 15px",
                  }}
                >
                  <span>
                    <span data-custom-class="body_text">
                      <span
                        style={{
                          fontSize: " 15px",
                        }}
                      >
                        <span>
                          <bdt class="statement-end-if-in-editor">
                            <bdt class="block-component">
                              {" "}
                              or any other application of ours that links to
                              this privacy notice
                            </bdt>
                          </bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span>
            <span data-custom-class="body_text">
              <span>
                <span data-custom-class="body_text">
                  <bdt class="block-component"></bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li
          style={{
            lineHeight: "1.5",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text">
                Engage with us in other related ways, including any sales,
                marketing, or events
                <span
                  style={{
                    fontSize: " 15px",
                  }}
                >
                  <span>
                    <span data-custom-class="body_text">
                      <span
                        style={{
                          fontSize: " 15px",
                        }}
                      >
                        <span>
                          <bdt class="statement-end-if-in-editor"></bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span>
            <span data-custom-class="body_text">
              <strong>Questions or concerns? </strong>Reading this privacy
              notice will help you understand your privacy rights and choices.
              If you do not agree with our policies and practices, please do not
              use our Services. If you still have any questions or concerns,
              please contact us at <bdt class="question">info@lirten.com</bdt>.
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      {/* <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div> */}
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <strong>
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
          </span>
        </strong>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">
            <strong>
              <em>
                This summary provides key points from our privacy notice, but
                you can find out more details about any of these topics by
                clicking the link following each key point or by using our 
              </em>
            </strong>
          </span>
        </span>

        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">
            <strong>
              <em>table of contents</em>
            </strong>
          </span>
        </span>

        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">
            <strong>
              <em> below to find the section you are looking for.</em>
            </strong>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">
            <strong>What personal information do we process?</strong> When you
            visit, use, or navigate our Services, we may process personal
            information depending on how you interact with{" "}
            <bdt class="block-component"></bdt>
            <bdt class="question">Lirten Solutions</bdt>
            <bdt class="else-block"></bdt> and the Services, the choices you
            make, and the products and features you use. Learn more about 
          </span>
        </span>

        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">
            personal information you disclose to us
          </span>
        </span>
        <span data-custom-class="body_text">.</span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">
            <strong>Do we process any sensitive personal information?</strong>
            <bdt class="block-component"></bdt>We do not process sensitive
            personal information.<bdt class="else-block"></bdt>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">
            <strong>Do we receive any information from third parties?</strong>
            <bdt class="block-component"></bdt>We do not receive any information
            from third parties.<bdt class="else-block"></bdt>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">
            <strong>How do we process your information?</strong> We process your
            information to provide, improve, and administer our Services,
            communicate with you, for security and fraud prevention, and to
            comply with law. We may also process your information for other
            purposes with your consent. We process your information only when we
            have a valid legal reason to do so. Learn more about 
          </span>
        </span>

        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">
            how we process your information
          </span>
        </span>

        <span data-custom-class="body_text">.</span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">
            <strong>
              In what situations and with which{" "}
              <bdt class="block-component"></bdt>parties do we share personal
              information?
            </strong>{" "}
            We may share information in specific situations and with specific{" "}
            <bdt class="block-component"></bdt>third parties. Learn more about 
          </span>
        </span>

        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">
            when and with whom we share your personal information
          </span>
        </span>

        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">
            .<bdt class="block-component"></bdt>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">
            <strong>How do we keep your information safe?</strong> We have{" "}
            <bdt class="block-component"></bdt>
            organizational<bdt class="statement-end-if-in-editor"></bdt> and
            technical processes and procedures in place to protect your personal
            information. However, no electronic transmission over the internet
            or information storage technology can be guaranteed to be 100%
            secure, so we cannot promise or guarantee that hackers,
            cybercriminals, or other <bdt class="block-component"></bdt>
            unauthorized
            <bdt class="statement-end-if-in-editor"></bdt> third parties will
            not be able to defeat our security and improperly collect, access,
            steal, or modify your information. Learn more about 
          </span>
        </span>

        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">
            how we keep your information safe
          </span>
        </span>

        <span data-custom-class="body_text">.</span>
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">
            <bdt class="statement-end-if-in-editor"></bdt>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      {/* <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div> */}
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">
            <strong>What are your rights?</strong> Depending on where you are
            located geographically, the applicable privacy law may mean you have
            certain rights regarding your personal information. Learn more
            about 
          </span>
        </span>

        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">your privacy rights</span>
        </span>

        <span data-custom-class="body_text">.</span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      {/* <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div> */}
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">
            <strong>How do you exercise your rights?</strong> The easiest way to
            exercise your rights is by{" "}
            <bdt class="block-component">submitting a </bdt>
          </span>
        </span>

        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">data subject access request</span>
        </span>
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">
            <bdt class="block-component"></bdt>, or by contacting us. We will
            consider and act upon any request in accordance with applicable data
            protection laws.
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      {/* <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div> */}
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">
            Want to learn more about what <bdt class="block-component"></bdt>
            <bdt class="question">Lirten Solutions</bdt>
            <bdt class="else-block"></bdt> does with any information we
            collect? 
          </span>
        </span>
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">
            Review the privacy notice in full
          </span>
        </span>
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">.</span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      {/* <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div> */}
      <div
        id="toc"
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span>
            <span>
              <strong>
                <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
              </strong>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span>1. WHAT INFORMATION DO WE COLLECT?</span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span>
            2. HOW DO WE PROCESS YOUR INFORMATION?
            <bdt class="block-component"></bdt>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span>
            3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </span>
          <span data-custom-class="body_text">
            <bdt class="block-component"></bdt>
          </span>
          <span>
            <span>
              <span data-custom-class="body_text">
                <span>
                  <bdt class="block-component"></bdt>
                </span>
              </span>
              <span data-custom-class="body_text">
                <span>
                  <span>
                    <span>
                      <bdt class="block-component"></bdt>
                    </span>
                  </span>
                  <bdt class="block-component"></bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span>4. HOW LONG DO WE KEEP YOUR INFORMATION?</span>
          <span>
            <span>
              <span data-custom-class="body_text">
                <span>
                  <span>
                    <bdt class="block-component"></bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</span>
          <span>
            <span>
              <span data-custom-class="body_text">
                <span>
                  <bdt class="statement-end-if-in-editor"></bdt>
                  <bdt class="block-component"></bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span>6. DO WE COLLECT INFORMATION FROM MINORS?</span>

          <span>
            <span>
              <span data-custom-class="body_text">
                <span>
                  <bdt class="statement-end-if-in-editor"></bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span>7. WHAT ARE YOUR PRIVACY RIGHTS?</span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span>8. CONTROLS FOR DO-NOT-TRACK FEATURES</span>
        </span>
      </div>
      {/* @#$#@$@#$@#$@$@# */}
      {/* @#$#@$@#$@#$@$@# */}
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span>9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span>
        </span>
      </div>
      {/* @#$#@$@#$@#$@$@# */}
      {/* @#$#@$@#$@#$@$@# */}
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span>10. DO WE MAKE UPDATES TO THIS NOTICE?</span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span>
          12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      {/* @#$#@$@#$@#$@$@# */}
      {/* @#$#@$@#$@#$@$@# */}
      <div
        id="infocollect"
        style={{
          lineHeight: "1.5",
        }}
      >
        <span>
          <span
            style={{
              fontSize: "15px",
            }}
          >
            <span
              style={{
                fontSize: "15px",
              }}
            >
              <span
                style={{
                  fontSize: "15px",
                }}
              >
                <span id="control">
                  <strong>
                    <span data-custom-class="heading_1">
                      1. WHAT INFORMATION DO WE COLLECT?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        id="personalinfo"
        style={{
          lineHeight: "1.5",
        }}
      >
        <span data-custom-class="heading_2">
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <strong>Personal information you disclose to us</strong>
          </span>
        </span>
      </div>
      <div>
        <div>
          <br />
        </div>
        <div
          style={{
            lineHeight: "1.5",
          }}
        >
          <span>
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text">
                <span
                  style={{
                    fontSize: " 15px",
                  }}
                >
                  <span
                    style={{
                      fontSize: " 15px",
                    }}
                  >
                    <span data-custom-class="body_text">
                      <strong>
                        <em>In Short:</em>
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
              <span data-custom-class="body_text">
                <span
                  style={{
                    fontSize: " 15px",
                  }}
                >
                  <span
                    style={{
                      fontSize: " 15px",
                    }}
                  >
                    <span data-custom-class="body_text">
                      <strong>
                        <em> </em>
                      </strong>
                      <em>
                        We collect personal information that you provide to us.
                      </em>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              We collect personal information that you voluntarily provide to us
              when you{" "}
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <bdt class="block-component"></bdt>
              </span>
              register on the Services, 
            </span>
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text">
                <span
                  style={{
                    fontSize: " 15px",
                  }}
                >
                  <bdt class="statement-end-if-in-editor"></bdt>
                </span>
              </span>
              <span data-custom-class="body_text">
                express an interest in obtaining information about us or our
                products and Services, when you participate in activities on the
                Services, or otherwise when you contact us.
              </span>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <bdt class="block-component"></bdt>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              <strong>Personal Information Provided by You.</strong> The
              personal information that we collect depends on the context of
              your interactions with us and the Services, the choices you make,
              and the products and features you use. The personal information we
              collect may include the following:
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span data-custom-class="body_text">
                  <bdt class="forloop-component"></bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li
          style={{
            lineHeight: "1.5",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text">
                <span
                  style={{
                    fontSize: " 15px",
                  }}
                >
                  <span data-custom-class="body_text">
                    <bdt class="question">names</bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span data-custom-class="body_text">
                  <bdt class="forloop-component"></bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li
          style={{
            lineHeight: "1.5",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text">
                <span
                  style={{
                    fontSize: " 15px",
                  }}
                >
                  <span data-custom-class="body_text">
                    <bdt class="question">phone numbers</bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span data-custom-class="body_text">
                  <bdt class="forloop-component"></bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li
          style={{
            lineHeight: "1.5",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text">
                <span
                  style={{
                    fontSize: " 15px",
                  }}
                >
                  <span data-custom-class="body_text">
                    <bdt class="question">email addresses</bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span data-custom-class="body_text">
                  <bdt class="forloop-component"></bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li
          style={{
            lineHeight: "1.5",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text">
                <span
                  style={{
                    fontSize: " 15px",
                  }}
                >
                  <span data-custom-class="body_text">
                    <bdt class="question">mailing addresses</bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span data-custom-class="body_text">
                  <bdt class="forloop-component"></bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li
          style={{
            lineHeight: "1.5",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text">
                <span
                  style={{
                    fontSize: " 15px",
                  }}
                >
                  <span data-custom-class="body_text">
                    <bdt class="question">job titles</bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span data-custom-class="body_text">
                  <bdt class="forloop-component"></bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li
          style={{
            lineHeight: "1.5",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text">
                <span
                  style={{
                    fontSize: " 15px",
                  }}
                >
                  <span data-custom-class="body_text">
                    <bdt class="question">usernames</bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span data-custom-class="body_text">
                  <bdt class="forloop-component"></bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li
          style={{
            lineHeight: "1.5",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text">
                <span
                  style={{
                    fontSize: " 15px",
                  }}
                >
                  <span data-custom-class="body_text">
                    <bdt class="question">passwords</bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span data-custom-class="body_text">
                  <bdt class="forloop-component"></bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li
          style={{
            lineHeight: "1.5",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text">
                <span
                  style={{
                    fontSize: " 15px",
                  }}
                >
                  <span data-custom-class="body_text">
                    <bdt class="question">contact preferences</bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span data-custom-class="body_text">
                  <bdt class="forloop-component"></bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li
          style={{
            lineHeight: "1.5",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text">
                <span
                  style={{
                    fontSize: " 15px",
                  }}
                >
                  <span data-custom-class="body_text">
                    <bdt class="question">contact or authentication data</bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span data-custom-class="body_text">
                  <bdt class="forloop-component"></bdt>
                </span>
                <span data-custom-class="body_text">
                  <bdt class="statement-end-if-in-editor"></bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div
        id="sensitiveinfo"
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">
            <strong>Sensitive Information.</strong>
            <bdt class="block-component"></bdt>We do not process sensitive
            information.
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span data-custom-class="body_text">
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <strong>Application Data.</strong> If you use our application(s), we
            also may collect the following information if you choose to provide
            us with access or permission:<bdt class="block-component"></bdt>
          </span>
        </span>
      </div>
      <ul>
        <li
          style={{
            lineHeight: "1.5",
          }}
        >
          <span data-custom-class="body_text">
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <em>Geolocation Information.</em> We may request access or
              permission to track location-based information from your mobile
              device, either continuously or while you are using our mobile
              application(s), to provide certain location-based services. If you
              wish to change our access or permissions, you may do so in your
              device's settings.<bdt class="statement-end-if-in-editor"></bdt>
            </span>
          </span>
        </li>
      </ul>
      {/* @#$#@$@#$@#$@$@# */} {/* @#$#@$@#$@#$@$@# */} {/* @#$#@$@#$@#$@$@# */}{" "}
      {/* @#$#@$@#$@#$@$@# */} {/* @#$#@$@#$@#$@$@# */} {/* @#$#@$@#$@#$@$@# */}
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        {/* @#$#@$@#$@#$@$@# */}
        <ul>
          <li
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text">
                <em>Push Notifications.</em> We may request to send you push
                notifications regarding your account or certain features of the
                application(s). If you wish to opt out from receiving these
                types of communications, you may turn them off in your device's
                settings.<bdt class="statement-end-if-in-editor"></bdt>
              </span>
            </span>
          </li>
        </ul>
        <div
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              This information is primarily needed to maintain the security and
              operation of our application(s), for troubleshooting, and for our
              internal analytics and reporting purposes.
            </span>
          </span>
        </div>
        <div
          style={{
            fontSize: " 15px",
          }}
        >
          <br />
        </div>
        <div
          style={{
            fontSize: " 15px",
          }}
        >
          <bdt class="statement-end-if-in-editor">
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text"></span>
            </span>
          </bdt>
        </div>
        <div
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text">
                All personal information that you provide to us must be true,
                complete, and accurate, and you must notify us of any changes to
                such personal information.
              </span>
            </span>
          </span>
        </div>
        <div
          style={{
            fontSize: " 15px",
          }}
        >
          <br />
        </div>
        <div
          id="infouse"
          style={{
            fontSize: " 15px",
          }}
        >
          <span>
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span
                  style={{
                    fontSize: " 15px",
                  }}
                >
                  <span id="control">
                    <strong>
                      <span data-custom-class="heading_1">
                        2. HOW DO WE PROCESS YOUR INFORMATION?
                      </span>
                    </strong>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </div>
        <div>
          <div
            style={{
              fontSize: " 15px",
            }}
          >
            <br />
          </div>
          <div
            style={{
              fontSize: " 15px",
            }}
          >
            <span>
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span data-custom-class="body_text">
                  <span
                    style={{
                      fontSize: " 15px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: " 15px",
                      }}
                    >
                      <span data-custom-class="body_text">
                        <strong>
                          <em>In Short: </em>
                        </strong>
                        <em>
                          We process your information to provide, improve, and
                          administer our Services, communicate with you, for
                          security and fraud prevention, and to comply with law.
                          We may also process your information for other
                          purposes with your consent.
                        </em>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
        </div>
        <div
          style={{
            fontSize: " 15px",
          }}
        >
          <br />
        </div>
        {/* <div  
          <br />
        </div> */}
        <div
          style={{
            lineHeight: "1.5",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text">
                <strong>
                  We process your personal information for a variety of reasons,
                  depending on how you interact with our Services, including:
                </strong>
                <bdt class="block-component"></bdt>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li
            style={{
              lineHeight: "1.5",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span data-custom-class="body_text">
                  <strong>
                    To facilitate account creation and authentication and
                    otherwise manage user accounts. 
                  </strong>
                  We may process your information so you can create and log in
                  to your account, as well as keep your account in working
                  order.
                  <span
                    style={{
                      fontSize: " 15px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: " 15px",
                      }}
                    >
                      <span data-custom-class="body_text">
                        <span
                          style={{
                            fontSize: " 15px",
                          }}
                        >
                          <span>
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: " 15px",
                                }}
                              >
                                <span>
                                  <span data-custom-class="body_text">
                                    <bdt class="statement-end-if-in-editor"></bdt>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <div
          style={{
            lineHeight: "1.5",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text">
                <bdt class="block-component"></bdt>
              </span>
            </span>
          </span>
        </div>
        <ul>
          <li
            style={{
              lineHeight: "1.5",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span data-custom-class="body_text">
                  <strong>
                    To deliver and facilitate delivery of services to the user. 
                  </strong>
                  We may process your information to provide you with the
                  requested service.
                  <span
                    style={{
                      fontSize: " 15px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: " 15px",
                      }}
                    >
                      <span data-custom-class="body_text">
                        <span
                          style={{
                            fontSize: " 15px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: " 15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: " 15px",
                                }}
                              >
                                <span>
                                  <span data-custom-class="body_text">
                                    <span
                                      style={{
                                        fontSize: " 15px",
                                      }}
                                    >
                                      <span>
                                        <span data-custom-class="body_text">
                                          <bdt class="statement-end-if-in-editor"></bdt>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        {/* !##$#@$#$#@%@#%#@%#@% */}
        {/* !##$#@$#$#@%@#%#@%#@% */}
        {/* !##$#@$#$#@%@#%#@%#@% */} {/* !##$#@$#$#@%@#%#@%#@% */}
        <ul>
          <li
            style={{
              lineHeight: "1.5",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span data-custom-class="body_text">
                  <strong>
                    To respond to user inquiries/offer support to users. 
                  </strong>
                  We may process your information to respond to your inquiries
                  and solve any potential issues you might have with the
                  requested service.
                  <span
                    style={{
                      fontSize: " 15px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: " 15px",
                      }}
                    >
                      <span data-custom-class="body_text">
                        <span
                          style={{
                            fontSize: " 15px",
                          }}
                        >
                          <span>
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: " 15px",
                                }}
                              >
                                <span>
                                  <span data-custom-class="body_text">
                                    <bdt class="statement-end-if-in-editor"></bdt>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        {/* !##$#@$#$#@%@#%#@%#@% */} {/* !##$#@$#$#@%@#%#@%#@% */}
        {/* !##$#@$#$#@%@#%#@%#@% */} {/* !##$#@$#$#@%@#%#@%#@% */}
        <ul>
          <li
            style={{
              lineHeight: "1.5",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span data-custom-class="body_text">
                  <strong>To send administrative information to you. </strong>We
                  may process your information to send you details about our
                  products and services, changes to our terms and policies, and
                  other similar information.
                  <span
                    style={{
                      fontSize: " 15px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: " 15px",
                      }}
                    >
                      <span data-custom-class="body_text">
                        <bdt class="statement-end-if-in-editor"></bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        {/* !##$#@$#$#@%@#%#@%#@% */} {/* !##$#@$#$#@%@#%#@%#@% */}
        {/* !##$#@$#$#@%@#%#@%#@% */} {/* !##$#@$#$#@%@#%#@%#@% */}
        <ul>
          <li
            style={{
              lineHeight: "1.5",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span data-custom-class="body_text">
                  <strong>
                    To <bdt class="block-component"></bdt>fulfill
                    <bdt class="statement-end-if-in-editor"></bdt> and manage
                    your orders. 
                  </strong>
                  We may process your information to
                  <bdt class="block-component"></bdt>fulfill
                  <bdt class="statement-end-if-in-editor"></bdt> and manage your
                  orders, payments, returns, and exchanges made through the
                  Services.
                  <span
                    style={{
                      fontSize: " 15px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: " 15px",
                      }}
                    >
                      <span data-custom-class="body_text">
                        <span
                          style={{
                            fontSize: " 15px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: " 15px",
                            }}
                          >
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: " 15px",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: " 15px",
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <bdt class="statement-end-if-in-editor"></bdt>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        {/* !##$#@$#$#@%@#%#@%#@% */} {/* !##$#@$#$#@%@#%#@%#@% */}
        {/* !##$#@$#$#@%@#%#@%#@% */} {/* !##$#@$#$#@%@#%#@%#@% */}
        <ul>
          <li
            style={{
              lineHeight: "1.5",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span>
                <span>
                  <span data-custom-class="body_text">
                    <strong>To enable user-to-user communications. </strong>We
                    may process your information if you choose to use any of our
                    offerings that allow for communication with another user.
                    <span>
                      <span>
                        <span data-custom-class="body_text">
                          <span>
                            <span data-custom-class="body_text">
                              <span>
                                <span data-custom-class="body_text">
                                  <bdt class="statement-end-if-in-editor"></bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        <ul>
          <li
            style={{
              lineHeight: "1.5",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span>
                <span>
                  <span data-custom-class="body_text">
                    <strong>To request feedback. </strong>We may process your
                    information when necessary to request feedback and to
                    contact you about your use of our Services.
                    <span>
                      <span>
                        <span data-custom-class="body_text">
                          <span>
                            <span data-custom-class="body_text">
                              <span>
                                <span data-custom-class="body_text">
                                  <bdt class="statement-end-if-in-editor"></bdt>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>
        {/* !##$#@$#$#@%@#%#@%#@% */} {/* !##$#@$#$#@%@#%#@%#@% */}
        {/* !##$#@$#$#@%@#%#@%#@% */} {/* !##$#@$#$#@%@#%#@%#@% */}
        {/* !##$#@$#$#@%@#%#@%#@% */} {/* !##$#@$#$#@%@#%#@%#@% */}
        {/* !##$#@$#$#@%@#%#@%#@% */} {/* !##$#@$#$#@%@#%#@%#@% */}
        <ul>
          <li
            style={{
              lineHeight: "1.5",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text">
                <strong>To post testimonials.</strong> We post testimonials on
                our Services that may contain personal information.
                <bdt class="statement-end-if-in-editor"></bdt>
              </span>
            </span>
          </li>
        </ul>
        <div
          style={{
            lineHeight: "1.5",
          }}
        >
          <bdt class="block-component">
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text"></span>
            </span>
          </bdt>
        </div>
        <ul>
          <li
            style={{
              lineHeight: "1.5",
            }}
          >
            <span data-custom-class="body_text">
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <strong>To protect our Services.</strong> We may process your
                information as part of our efforts to keep our Services safe and
                secure, including fraud monitoring and prevention.
              </span>
            </span>
            <bdt class="statement-end-if-in-editor">
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span data-custom-class="body_text"></span>
              </span>
            </bdt>
          </li>
        </ul>
        {/* ehjfsaiofajfpapfjfaopfaofjaospjf */}
        {/* ehjfsaiofajfpapfjfaopfaofjaospjf */}
        {/* ehjfsaiofajfpapfjfaopfaofjaospjf */}
        {/* ehjfsaiofajfpapfjfaopfaofjaospjf */}
        <ul>
          <li
            style={{
              lineHeight: "1.5",
            }}
          >
            <span data-custom-class="body_text">
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <strong>
                  To evaluate and improve our Services, products, marketing, and
                  your experience.
                </strong>{" "}
                We may process your information when we believe it is necessary
                to identify usage trends, determine the effectiveness of our
                promotional campaigns, and to evaluate and improve our Services,
                products, marketing, and your experience.
              </span>
            </span>
            <bdt class="statement-end-if-in-editor">
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span data-custom-class="body_text"></span>
              </span>
            </bdt>
          </li>
        </ul>
        {/* ehjfsaiofajfpapfjfaopfaofjaospjf */}
        {/* ehjfsaiofajfpapfjfaopfaofjaospjf */}
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        id="whoshare"
        style={{
          lineHeight: "1.5",
        }}
      >
        <span>
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span id="control">
                  <strong>
                    <span data-custom-class="heading_1">
                      3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                      INFORMATION?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              <strong>
                <em>In Short:</em>
              </strong>
              <em>
                 We may share information in specific situations described in
                this section and/or with the following
                <bdt class="block-component"></bdt>third parties.
              </em>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">
            We
            <bdt class="block-component"></bdt>may need to share your personal
            information in the following situations:
          </span>
        </span>
      </div>
      <ul>
        <li
          style={{
            lineHeight: "1.5",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              <strong>Business Transfers.</strong>
              We may share or transfer your information in connection with, or
              during negotiations of, any merger, sale of company assets,
              financing, or acquisition of all or a portion of our business to
              another company.
            </span>
          </span>
        </li>
      </ul>
      {/* ehjfsaiofajfpapfjfaopfaofjaospjf */}
      {/* ehjfsaiofajfpapfjfaopfaofjaospjf */}
      {/* ehjfsaiofajfpapfjfaopfaofjaospjf */}
      {/* ehjfsaiofajfpapfjfaopfaofjaospjf */}
      {/* ehjfsaiofajfpapfjfaopfaofjaospjf */}
      {/* ehjfsaiofajfpapfjfaopfaofjaospjf */}
      <ul>
        <li
          style={{
            lineHeight: "1.5",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              <strong>Other Users.</strong>
              When you share personal information
              <bdt class="block-component"></bdt>
              (for example, by posting comments, contributions, or other content
              to the Services)
              <bdt class="statement-end-if-in-editor"></bdt>
              or otherwise interact with public areas of the Services, such
              personal information may be viewed by all users and may be
              publicly made available outside the Services in perpetuity.
              <bdt class="block-component"></bdt>
              Similarly, other users will be able to view descriptions of your
              activity, communicate with you within our Services, and view your
              profile.
              <bdt class="statement-end-if-in-editor"></bdt>
            </span>
          </span>
        </li>
      </ul>
      {/* //s/adas[f/[/f[a//f]]] */}
      {/* //s/adas[f/[/f[a//f]]] */}
      {/* //s/adas[f/[/f[a//f]]] */}
      {/* //s/adas[f/[/f[a//f]]] */}
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        id="inforetain"
        style={{
          lineHeight: "1.5",
        }}
      >
        <span>
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span id="control">
                  <strong>
                    <span data-custom-class="heading_1">
                      4. HOW LONG DO WE KEEP YOUR INFORMATION?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              <strong>
                <em>In Short:</em>
              </strong>
              <em>
                We keep your information for as long as necessary to
                <bdt class="block-component"></bdt>
                fulfill
                <bdt class="statement-end-if-in-editor"></bdt>
                the purposes outlined in this privacy notice unless otherwise
                required by law.
              </em>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting, or other legal requirements).
              <bdt class="block-component"></bdt>
              No purpose in this notice will require us keeping your personal
              information for longer than
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span>
                  <span data-custom-class="body_text">
                    <bdt class="block-component"></bdt>
                  </span>
                </span>
              </span>
              <bdt class="block-component"></bdt>
              the period of time in which users have an account with us
              <bdt class="block-component"></bdt>
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span>
                  <span data-custom-class="body_text">
                    <bdt class="else-block"></bdt>
                  </span>
                </span>
              </span>
              .
            </span>
          </span>
        </span>
      </div>
      {/* //s/adas[f/[/f[a//f]]] */}
      {/* //s/adas[f/[/f[a//f]]] */}
      {/* //s/adas[f/[/f[a//f]]] */}
      {/* //s/adas[f/[/f[a//f]]] */}
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or
              <bdt class="block-component"></bdt>
              anonymize
              <bdt class="statement-end-if-in-editor"></bdt>
              such information, or, if this is not possible (for example,
              because your personal information has been stored in backup
              archives), then we will securely store your personal information
              and isolate it from any further processing until deletion is
              possible.
              <span>
                <bdt class="block-component"></bdt>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        id="infosafe"
        style={{
          lineHeight: "1.5",
        }}
      >
        <span>
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span id="control">
                  <strong>
                    <span data-custom-class="heading_1">
                      5. HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              <strong>
                <em>In Short: </em>
              </strong>
              <em>
                We aim to protect your personal information through a system of
                <bdt class="block-component"></bdt>
                organizational
                <bdt class="statement-end-if-in-editor"></bdt>
                and technical security measures.
              </em>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              We have implemented appropriate and reasonable technical and
              <bdt class="block-component"></bdt>
              organizational
              <bdt class="statement-end-if-in-editor"></bdt>
              security measures designed to protect the security of any personal
              information we process. However, despite our safeguards and
              efforts to secure your information, no electronic transmission
              over the Internet or information storage technology can be
              guaranteed to be 100% secure, so we cannot promise or guarantee
              that hackers, cybercriminals, or other
              <bdt class="block-component"></bdt>
              unauthorized
              <bdt class="statement-end-if-in-editor"></bdt>
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Although we will do our best to protect your personal information,
              transmission of personal information to and from our Services is
              at your own risk. You should only access the Services within a
              secure environment.
              <span>
                <bdt class="statement-end-if-in-editor"></bdt>
              </span>
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span data-custom-class="body_text">
                  <bdt class="block-component"></bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      {/* ??????????????@############## */}
      {/* ??????????????@############## */}
      {/* ??????????????@############## */}
      {/* ??????????????@############## */}
      <div
        id="infominors"
        style={{
          lineHeight: "1.5",
        }}
      >
        <span>
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span id="control">
                  <strong>
                    <span data-custom-class="heading_1">
                      6. DO WE COLLECT INFORMATION FROM MINORS?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              <strong>
                <em>In Short:</em>
              </strong>
              <em>
                 We do not knowingly collect data from or market to
                <bdt class="block-component"></bdt>
                children under 18 years of age
                <bdt class="else-block"></bdt>.
              </em>
              <bdt class="block-component"></bdt>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Services, you represent that you are
              at least 18 or that you are the parent or guardian of such a minor
              and consent to such minor dependent’s use of the Services. If we
              learn that personal information from users less than 18 years of
              age has been collected, we will deactivate the account and take
              reasonable measures to promptly delete such data from our records.
              If you become aware of any data we may have collected from
              children under age 18, please contact us at
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span data-custom-class="body_text">
                  <bdt class="block-component"></bdt>
                  <bdt class="question">info@lirten.com</bdt>
                  <bdt class="else-block"></bdt>
                </span>
              </span>
              .
            </span>
            <span data-custom-class="body_text"></span>
          </span>
        </span>
      </div>
      {/* ??????????????@############## */}
      {/* ??????????????@############## */}
      {/* ??????????????@############## */}
      {/* ??????????????@############## */}
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        id="privacyrights"
        style={{
          lineHeight: "1.5",
        }}
      >
        <span>
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span id="control">
                  <strong>
                    <span data-custom-class="heading_1">
                      7. WHAT ARE YOUR PRIVACY RIGHTS?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              <strong>
                <em>In Short:</em>
              </strong>
              <em>
                 
                <span>
                  <span
                    style={{
                      fontSize: " 15px",
                    }}
                  >
                    <span data-custom-class="body_text">
                      <em>
                        <bdt class="block-component"></bdt>
                      </em>
                    </span>
                  </span>
                   
                </span>
                You may review, change, or terminate your account at any time.
              </em>
              <span>
                <span
                  style={{
                    fontSize: " 15px",
                  }}
                >
                  <bdt class="block-component"></bdt>
                </span>
              </span>
            </span>
          </span>{" "}
        </span>

        <div
          style={{
            lineHeight: "1.5",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
             
          </span>
        </div>
        <div
          style={{
            lineHeight: "1.5",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text">
                If you are located in the EEA or UK and you believe we are
                unlawfully processing your personal information, you also have
                the right to complain to your
                <span
                  style={{
                    fontSize: " 15px",
                  }}
                >
                  <span>
                    <span data-custom-class="body_text">
                      <span>
                        <span data-custom-class="body_text">
                          <span
                            style={{
                              fontSize: " 15px",
                            }}
                          >
                            Member State data protection authority
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                or 
              </span>
            </span>
          </span>

          <span data-custom-class="link">
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span data-custom-class="body_text">
                  UK data protection authority
                </span>
              </span>
            </span>
          </span>

          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text">.</span>
            </span>
          </span>
        </div>
        <div
          style={{
            lineHeight: "1.5",
          }}
        >
          <br />
        </div>
        <div
          style={{
            lineHeight: "1.5",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text">
                If you are located in Switzerland, you may contact the
                <span
                  style={{
                    fontSize: " 15px",
                  }}
                >
                  <span>
                    <span data-custom-class="body_text">
                      <span>
                        <span data-custom-class="body_text">
                          <span
                            style={{
                              fontSize: " 15px",
                            }}
                          >
                            Federal Data Protection and Information Commissioner
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                .
              </span>
            </span>
          </span>
        </div>
        <div
          style={{
            lineHeight: "1.5",
          }}
        >
          <br />
        </div>
        <div
          id="withdrawconsent"
          style={{
            lineHeight: "1.5",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text">
                <strong>
                  <u>Withdrawing your consent:</u>
                </strong>
                If we are relying on your consent to process your personal
                information,
                <bdt class="block-component"></bdt>
                which may be express and/or implied consent depending on the
                applicable law,
                <bdt class="statement-end-if-in-editor"></bdt>
                you have the right to withdraw your consent at any time. You can
                withdraw your consent at any time by contacting us by using the
                contact details provided in the section
                <bdt class="block-component"></bdt>"
                <bdt class="statement-end-if-in-editor"></bdt>
              </span>
            </span>
          </span>

          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text">
                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </span>
            </span>
          </span>

          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span data-custom-class="body_text">
                <bdt class="block-component"></bdt>"
                <bdt class="statement-end-if-in-editor"></bdt>
                below
                <bdt class="block-component"></bdt>.
              </span>
            </span>
          </span>
        </div>
        <div
          style={{
            lineHeight: "1.5",
          }}
        >
          <br />
        </div>
      </div>
      {/* 233332523543545345435435423525 */}
      {/*233332523543545345435435423525 */}
      {/*233332523543545345435435423525 */}
      {/*233332523543545345435435423525 */}
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal nor,
            <bdt class="block-component"></bdt>
            when applicable law allows,
            <bdt class="statement-end-if-in-editor"></bdt>
            will it affect the processing of your personal information conducted
            in reliance on lawful processing grounds other than consent.
            <bdt class="block-component"></bdt>
          </span>
        </span>
        <bdt class="block-component">
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text"></span>
          </span>
        </bdt>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="heading_2">
            <strong>Account Information</strong>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span data-custom-class="body_text">
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
            <bdt class="forloop-component"></bdt>
          </span>
        </span>
      </div>
      {/* 233332523543545345435435423525 */}
      {/*233332523543545345435435423525 */}
      {/*233332523543545345435435423525 */}
      {/*233332523543545345435435423525 */}
      <ul>
        <li
          style={{
            lineHeight: "1.5",
          }}
        >
          <span data-custom-class="body_text">
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <bdt class="question">
                Log in to your account settings and update your user account.
              </bdt>
            </span>
          </span>
        </li>
      </ul>
      {/*233332523543545345435435423525 */}
      {/*233332523543545345435435423525 */}
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal
            requirements.
          </span>
        </span>
      </div>
      {/*211449494949439043209402309394092493209402394029309402949230 */}
      {/*211449494949439043209402309394092493209402394029309402949230 */}
      {/*211449494949439043209402309394092493209402394029309402949230 */}
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span data-custom-class="body_text">
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            If you have questions or comments about your privacy rights, you may
            email us at <bdt class="question">info@lirten.com</bdt>.
          </span>
        </span>
        <bdt class="statement-end-if-in-editor">
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text"></span>
          </span>
        </bdt>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        id="DNT"
        style={{
          lineHeight: "1.5",
        }}
      >
        <span>
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span id="control">
                  <strong>
                    <span data-custom-class="heading_1">
                      8. CONTROLS FOR DO-NOT-TRACK FEATURES
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      {/* ////23123/13/3/1/312/312/321/3/12 */}
      {/* ////23123/13/3/1/312/312/321/3/12 */}
      {/* ////23123/13/3/1/312/312/321/3/12 */}
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track (
              <bdt class="block-component"></bdt>
              "DNT"
              <bdt class="statement-end-if-in-editor"></bdt>) feature or setting
              you can activate to signal your privacy preference not to have
              data about your online browsing activities monitored and
              collected. At this stage no uniform technology standard for
              <bdt class="block-component"></bdt>
              recognizing
              <bdt class="statement-end-if-in-editor"></bdt>
              and implementing DNT signals has been
              <bdt class="block-component"></bdt>
              finalized
              <bdt class="statement-end-if-in-editor"></bdt>. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a
              revised version of this privacy notice.
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      {/* //32[434[2/33[24[234[23432[4/32[]]]]]]] */}
      {/* //32[434[2/33[24[234[23432[4/32[]]]]]]] */}
      {/* //32[434[2/33[24[234[23432[4/32[]]]]]]] */}
      {/* //32[434[2/33[24[234[23432[4/32[]]]]]]] */}
      {/* //32[434[2/33[24[234[23432[4/32[]]]]]]] */}
      <div
        id="caresidents"
        style={{
          lineHeight: "1.5",
        }}
      >
        <span>
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span id="control">
                  <strong>
                    <span data-custom-class="heading_1">
                      9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              <strong>
                <em>In Short: </em>
              </strong>
              <em>
                Yes, if you are a resident of California, you are granted
                specific rights regarding access to your personal information.
              </em>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              California Civil Code Section 1798.83, also known as the
              <bdt class="block-component"></bdt>
              "Shine The Light"
              <bdt class="statement-end-if-in-editor"></bdt>
              law, permits our users who are California residents to request and
              obtain from us, once a year and free of charge, information about
              categories of personal information (if any) we disclosed to third
              parties for direct marketing purposes and the names and addresses
              of all third parties with which we shared personal information in
              the immediately preceding calendar year. If you are a California
              resident and would like to make such a request, please submit your
              request in writing to us using the contact information provided
              below.
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      {/* sq/[qd/as'/sa'f'a] */}
      <div>
        <span
          style={{
            lineHeight: "1.5",
          }}
        >
          <span
            style={{
              lineHeight: "1.5",
            }}
          >
            <span data-custom-class="body_text">
              If you are under 18 years of age, reside in California, and have a
              registered account with Services, you have the right to request
              removal of unwanted data that you publicly post on the Services.
              To request removal of such data, please contact us using the
              contact information provided below and include the email address
              associated with your account and a statement that you reside in
              California. We will make sure the data is not publicly displayed
              on the Services, but please be aware that the data may not be
              completely or comprehensively removed from all our systems (e.g.
              <bdt class="block-component"></bdt>,
              <bdt class="statement-end-if-in-editor"></bdt>
              backups, etc.).
              <span
                style={{
                  lineHeight: "1.5",
                }}
              >
                <span data-custom-class="body_text"></span>
              </span>
            </span>
          </span>{" "}
        </span>
      </div>
      {/* //\/\/\/\/\/\ */}
      {/* //\/\/\/\/\/\ */}
      {/* //\/\/\/\/\/\ */}
      {/* //\/\/\/\/\/\ */}
      {/* //\/\/\/\/\/\ */}
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        id="policyupdates"
        style={{
          lineHeight: "1.5",
        }}
      >
        <span>
          <span
            style={{
              lineHeight: "1.5",
            }}
          >
            <span
              style={{
                lineHeight: "1.5",
              }}
            >
              <span
                style={{
                  lineHeight: "1.5",
                }}
              >
                <span id="control">
                  <strong>
                    <span data-custom-class="heading_1">
                      10. DO WE MAKE UPDATES TO THIS NOTICE?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            lineHeight: "1.5",
          }}
        >
          <span
            style={{
              lineHeight: "1.5",
            }}
          >
            <span data-custom-class="body_text">
              <em>
                <strong>In Short: </strong>Yes, we will update this notice as
                necessary to stay compliant with relevant laws.
              </em>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      {/* /'/'/'/'/'/'/'/'/ */}
      {/* /'/'/'/'/'/'/'/'/ */}
      {/* /'/'/'/'/'/'/'/'/ */}
      {/* /'/'/'/'/'/'/'/'/ */}
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated
              <bdt class="block-component"></bdt>
              "Revised"
              <bdt class="statement-end-if-in-editor"></bdt>
              date and the updated version will be effective as soon as it is
              accessible. If we make material changes to this privacy notice, we
              may notify you either by prominently posting a notice of such
              changes or by directly sending you a notification. We encourage
              you to review this privacy notice frequently to be informed of how
              we are protecting your information.
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      {/* /s'ad/s'/da'd/sa'd'sa/d/sa'/d'/as'dsa'ds/d' */}
      {/* /s'ad/s'/da'd/sa'd'sa/d/sa'/d'/as'dsa'ds/d' */}
      {/* /s'ad/s'/da'd/sa'd'sa/d/sa'/d'/as'dsa'ds/d' */}
      {/* /s'ad/s'/da'd/sa'd'sa/d/sa'/d'/as'dsa'ds/d' */}
      {/* /s'ad/s'/da'd/sa'd'sa/d/sa'/d'/as'dsa'ds/d' */}
      {/* /s'ad/s'/da'd/sa'd'sa/d/sa'/d'/as'dsa'ds/d' */}
      <div
        id="contact"
        style={{
          lineHeight: "1.5",
        }}
      >
        <span>
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span id="control">
                  <strong>
                    <span data-custom-class="heading_1">
                      11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              If you have questions or comments about this notice, you may
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span data-custom-class="body_text">
                  <bdt class="block-component"></bdt>
                  email us at
                  <strong class="question"> info@lirten.com</strong>
                  <bdt class="statement-end-if-in-editor"></bdt>
                </span>
              </span>
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span
                  style={{
                    fontSize: " 15px",
                  }}
                >
                  <span data-custom-class="body_text">
                     or contact us by post at:
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              <span
                style={{
                  fontSize: " 15px",
                  fontWeight: "bolder",
                }}
              >
                <span>
                  <span>
                    <span data-custom-class="body_text">
                      <bdt class="question">
                        Lirten Solutions For Information Technologies
                      </bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
            <span data-custom-class="body_text">
              <span></span>
            </span>{" "}
          </span>{" "}
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
            fontWeight: "bolder",
          }}
        >
          <span data-custom-class="body_text">
            <bdt class="question">Building 25, 21/79, Maadi, Cairo</bdt>
            <span>
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <bdt class="block-component"></bdt>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span data-custom-class="body_text">
            <span>
              <span
                style={{
                  fontSize: " 15px",
                  fontWeight: "bold",
                }}
              >
                <bdt class="question">01028313383</bdt>
                <br></br>
                <bdt class="question">Cairo / Maadi </bdt>
                <bdt class="statement-end-if-in-editor"></bdt>
                <bdt class="block-component"></bdt>
                <bdt class="question"> 11728</bdt>
                <bdt class="statement-end-if-in-editor"></bdt>
                <bdt class="block-component"></bdt>
                <bdt class="block-component"></bdt>
              </span>
            </span>
             
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        id="request"
        style={{
          lineHeight: "1.5",
        }}
      >
        <span>
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span
              style={{
                fontSize: " 15px",
              }}
            >
              <span
                style={{
                  fontSize: " 15px",
                }}
              >
                <span id="control">
                  <strong>
                    <span data-custom-class="heading_1">
                      12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                      COLLECT FROM YOU?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <br />
      </div>
      <div
        style={{
          lineHeight: "1.5",
        }}
      >
        <span
          style={{
            fontSize: " 15px",
          }}
        >
          <span
            style={{
              fontSize: " 15px",
            }}
          >
            <span data-custom-class="body_text">
              <bdt class="block-component"></bdt>
              You have the right to request access to the personal information
              we collect from you, change that information, or delete it.
              <bdt class="statement-end-if-in-editor"></bdt>
              To request to review, update, or delete your personal information,
              please
              <bdt class="block-component"></bdt>
              fill out and submit a 
            </span>
            <span>
              <span data-custom-class="body_text">
                <span
                  style={{
                    fontSize: " 15px",
                  }}
                >
                  data subject access request
                </span>
              </span>
            </span>
          </span>
        </span>
        <span data-custom-class="body_text">.</span>
      </div>
    </div>
  );
}
