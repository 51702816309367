import "./getOurApp.css";
import React from "react";
import { getOurAppTextDetails } from "./getOurAppData";
function GetOurAppSection() {
  return (
    <div className="getOurAppSectionStyle">
      <div className="leftSideSectionGetSec">
        <h1 className="titleSectionStyle">
          {getOurAppTextDetails.sectionTitle1}
        </h1>
        <h1 className="titleSectionStyle">
          {getOurAppTextDetails.sectionTitle2}
        </h1>
        <h3 className="textSectionStyle">{getOurAppTextDetails.sectionText}</h3>
      </div>
      <div className="rightSideSectionGetSec">
        {getOurAppTextDetails.elementsOfApp.map((singleElement, index) => (
          <div className="singleItemStyleApp" key={index}>
            <img
              src={singleElement.icon}
              className="styleIconApp"
              alt="items"
            />
            <div className="elementsTextDiv">
              <h1 className="singleElementTitle">{singleElement.title}</h1>
              <h3 className="singleElementText">{singleElement.text1}</h3>
              {/* <h3 className="singleElementText">{singleElement.text2}</h3> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default GetOurAppSection;
