const getOurAppTextDetails = {
  sectionTitle1: "Get our app for a",
  sectionTitle2: "workspace on your terms!",
  sectionText:
    "Connect with passionate professionals, innovators and entrepreneurs in various fields.",
  elementsOfApp: [
    {
      title: "Booking",
      text1: "You can effortlessly browse through available time slots",
      //   text2: "through available time slots",
      icon: "https://tbhwebsite.s3.me-south-1.amazonaws.com/bookingIcon.webp",
    },
    {
      title: "Door Access",
      text1: "Enhance security, eliminate the need for physical keys",
      //   text2: "need for physical keys",
      icon: "https://tbhwebsite.s3.me-south-1.amazonaws.com/doorAccessIcon.webp",
    },
    {
      title: "Invoicing",
      text1: "Stay organized and track payment statuses all within the app",
      //   text2: "statuses all within the app",
      icon: "https://tbhwebsite.s3.me-south-1.amazonaws.com/invoicingIcon.webp",
    },
    {
      title: "Attendance",
      text1: "Simplify attendance reporting and gain valuable insights",
      //   text2: "and gain valuable insights",
      icon: "https://tbhwebsite.s3.me-south-1.amazonaws.com/attendenceIocn.webp",
    },
  ],
};
// 👇️ named exports
export { getOurAppTextDetails };
