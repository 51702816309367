import React from "react";
import "./facilities.css";
function Facilities() {
  var listOfELements = [
    {
      title: "Free Wifi",
      text1: "Stay",
      text2: "Connected",
      icon: "https://tbhwebsite.s3.me-south-1.amazonaws.com/freeWifiIcon.webp",
    },
    {
      title: "Security",
      text1: "Enhancing",
      text2: "Safety",
      icon: "https://tbhwebsite.s3.me-south-1.amazonaws.com/securityIcon.webp",
    },
    {
      title: "Printer",
      text1: "Print",
      text2: "with Ease",
      icon: "https://tbhwebsite.s3.me-south-1.amazonaws.com/printerIcon.webp",
    },
    {
      title: "LED Screen",
      text1: "Brilliant",
      text2: "Visuals",
      icon: "https://tbhwebsite.s3.me-south-1.amazonaws.com/LedIcon.webp",
    },
    {
      title: "White Board",
      text1: "Team",
      text2: "Planning",
      icon: "https://tbhwebsite.s3.me-south-1.amazonaws.com/whiteBoardIcon.webp",
    },
    {
      title: "Cafeteria",
      text1: "Hot",
      text2: "Beverages",
      icon: "https://tbhwebsite.s3.me-south-1.amazonaws.com/cafteriaIcon.webp",
    },
  ];

  return (
    <div className="mainContainerFacilities">
      <div className="facilitiesContainer">
        <div className="hrStyle"></div>
        <div className="elementsFacilities">
          {listOfELements.map((singleItem, index) => (
            <div key={index} className="singleELement">
              <img
                src={singleItem.icon}
                alt={singleItem.title}
                className="iconStyleItem"
              />
              <div className="itemDetails">
                <h1 className="itemDetailsTitle">{singleItem.title}</h1>

                <h3 className="itemDetailsText">{singleItem.text1}</h3>

                <h3 className="itemDetailsText">{singleItem.text2}</h3>
              </div>
            </div>
          ))}
        </div>
        <div className="hrStyle"></div>
      </div>
    </div>
  );
}

export default Facilities;
