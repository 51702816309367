import React, { useState, useEffect } from "react";
import { companiesDetails } from "./companiesData";
import "./companies.css";

function Companies() {
  const [sizeWebView, setWindowWidth] = useState(() => {
    const savedSizeWebView = localStorage.getItem("savedSizeWebView");
    return savedSizeWebView ? savedSizeWebView : window.innerWidth;
  });
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const [startIndex, setStartIndex] = useState(0);
  //   const [currentIndex, setCurrentIndex] = useState(0);
  // var itemsPerPage = 13;
  var itemsPerPage =
    sizeWebView >= 1235
      ? 13
      : // :
      //  sizeWebView >= 825
      // ? 13
      sizeWebView >= 825
      ? 13
      : sizeWebView <= 400
      ? 7
      : 10;
  // var itemsPerPage = 13;
  const shiftItems = (count) => {
    const newIndex =
      (startIndex + count + companiesDetails.companiesElements.length) %
      companiesDetails.companiesElements.length;
    setStartIndex(newIndex);
  };

  const visibleItems = [];
  for (let i = 0; i < itemsPerPage; i++) {
    const index = (startIndex + i) % companiesDetails.companiesElements.length;
    visibleItems.push(companiesDetails.companiesElements[index]);
  }
  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    localStorage.setItem("savedSizeWebView", sizeWebView);
  }, [sizeWebView]);
  return (
    <div className="companiesSection">
      {/* ############ )))))))) ##############  */}
      {/* left side container  */}
      <div className="leftSectionCompanies">
        <h1 className="titleStyleCompanies">
          {companiesDetails.sectionTitle1}
        </h1>
        <div className="communityDiv">
          {/* <h1 className="titleStyleCompaniesWhite"> */}
          {companiesDetails.sectionTitle2}
          {/* </h1> */}
        </div>
        <div className="divTextCompanies">
          <h3 className="textStyleCompanies">
            {companiesDetails.sectionText1}
          </h3>
          <h3 className="textStyleCompanies">
            {companiesDetails.sectionText2}
          </h3>
        </div>
        <button
          className="btnCompanies"
          onClick={() => {
            const element = document.getElementById("contactUsID");
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }}
        >
          {companiesDetails.btnText}
        </button>
      </div>
      {/* ############ )))))))) ##############  */}
      {/* right side container  */}
      <div className="rightSectionCompanies">
        <div
          className="divCarArrow"
          onClick={() => {
            shiftItems(1);
          }}
        >
          <img
            src={
              "https://tbhwebsite.s3.me-south-1.amazonaws.com/leftCarouselCompanies.webp"
            }
            alt="leftIconCompany"
            className="leftCompanyCarStyle"
          />
        </div>
        {visibleItems.map((singleElement, index) => (
          <img
            src={singleElement}
            alt={`element-${index}`}
            key={index}
            className="singleIconStyle"
          />
        ))}
        <div
          className="divCarArrow"
          onClick={() => {
            shiftItems(-1);
          }}
        >
          <img
            src={
              "https://tbhwebsite.s3.me-south-1.amazonaws.com/rightCarouselCompanies.webp"
            }
            alt="rightIconCompany"
            className="rightCompanyCarStyle"
          />
        </div>
      </div>
    </div>
  );
}

export default Companies;
