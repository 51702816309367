import "./navbar.css";
import React from "react";

function Navbar() {
  return (
    <div className="navbarSection">
      <div className="navbarContent">
        <img
          src={"https://tbhwebsite.s3.me-south-1.amazonaws.com/navbarLogo.webp"}
          alt="logo"
          className="logoWebView"
        />
        <button
          className="btnNavBar"
          onClick={() => {
            const element = document.getElementById("contactUsID");
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }}
        >
          CONTACT US
        </button>
      </div>
    </div>
  );
}

export default Navbar;
