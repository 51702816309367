import "./contactUs.css";
import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { formLeftData } from "./contactUsData";
import axios from "axios";
import CustomAlert from "../customAlert/CustomAlert";
// import CountryDropdown from "./CountryDropdown";
import countriesData from "./countries.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
function Contact() {
  // ! PRODUCTION LIVE
  // const emailLink = "https://inquiry.thebusinesshub.space/inquiry";
  const emailLink = " https://pf.app.thebusinesshub.space/inquiry";
  // const emailLink = " https://pf.uat.app.thebusinesshub.space/inquiry";

  // ! UAT
  // const emailLink = "https://pf.uat.app.thebusinesshub.space/inquiry";
  const [showCaptcha, setShowCaptcha] = useState(false);
  const handleCaptchaAppear = () => {
    // Set showCaptcha to true when the button is clicked
    setShowCaptcha(true);
  };
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [isVerified, setVerified] = useState(false);

  const handleVerification = (response) => {
    // 'response' will be null if user interaction with reCAPTCHA failed
    setVerified(response !== null);
    // Trigger the action after verification is done
  };

  const handleButtonClick = () => {
    setShowAlert(true);
  };

  const handleAlertClose = () => {
    setShowCaptcha(false);
    setVerified(false);
    setShowAlert(false);
    window.location.reload();
  };
  const validateName = (name) => {
    // const namePattern = /^[a-zA-Z\s]+$/;
    return name.length > 0;
  };
  const validateEmail = (value) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(value);
  };
  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^[0-9]+$/;
    return phoneNumberPattern.test(phoneNumber);
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
    if (isSubmitted) {
      setNameError(validateName(value) ? "" : "*This field is required");
    }
  };
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (isSubmitted && value.length > 0) {
      setEmailError(validateEmail(value) ? "" : "*Invalid email address");
    } else {
      setEmailError("");
    }
  };
  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;

    setPhoneNumber(value);

    if (isSubmitted) {
      setPhoneNumberError(
        validatePhoneNumber(`${value}`) ? "" : "*This field is required"
      );
    }
  };
  const handleMessageChange = (e) => {
    const value = e.target.value;
    setMessage(value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setNameError(validateName(name) ? "" : "*This field is required");
    if (email.length > 0) {
      setEmailError(validateEmail(email) ? "" : "*Invalid email address");
    }
    setPhoneNumberError(
      validatePhoneNumber(`${phoneNumber}`) ? "" : "*This field is required"
    );

    if (nameError.length === 0 && phoneNumberError.length === 0 && isVerified) {
      // if () {

      var enquiryData = {
        name: name,
        phone: selectedOption.dial_code + phoneNumber,
      };
      if (email.length > 0) {
        enquiryData["email"] = email;
      }
      if (message.length > 0) {
        enquiryData["message"] = message;
      }

      const response = await axios.post(emailLink, enquiryData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.exitCode === 0) {
        handleButtonClick();
      }

      // }
    }
  };
  useEffect(() => {
    // Execute the action when isCaptchaVerified changes
    if (isVerified) {
      handleSubmit({ preventDefault: () => {} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVerified]);

  // const [selectedCountry, setSelectedCountry] = useState(null);
  // console.log(selectedCountry);
  // const handleCountrySelect = (selectedOption) => {
  //   setSelectedCountry(selectedOption);
  // };
  // State to manage the selected option
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const selectedObject = countriesData.find(
      (option) => option.dial_code === selectedValue
    );
    setSelectedOption(selectedObject);
  };

  return (
    <>
      {/* Google reCAPTCHA */}
      {showCaptcha ? (
        <div>
          <div className="overlay"></div>
          <ReCAPTCHA
            className="captchaStyle"
            sitekey="6LcEZSkpAAAAANHpJAR61UZmDnlemrPGO-gkmFOg" // Replace with your Site Key
            onChange={handleVerification}
          />
        </div>
      ) : (
        <div></div>
      )}
      {showAlert ? (
        <CustomAlert
          title={"Message Sent!"}
          children={
            <p>
              Your message has been sent successfully. We'll be in touch
              shortly. Thank you for reaching out!
            </p>
          }
          onClose={handleAlertClose}
        />
      ) : (
        <div></div>
      )}
      <div className="contactUsSection" id="contactUsID">
        {/* ################## */}
        {/* left side section */}
        <div className="leftSideContact">
          <h1 className="formContactTitle">{formLeftData.sectionTitle1}</h1>
          <h1 className="formContactText">{formLeftData.sectionText}</h1>
          <div
            className={
              nameError && phoneNumberError
                ? "formContactContentError"
                : "formContactContent"
            }
          >
            <div className="aroundError">
              <div
                className={
                  nameError
                    ? "inputContactContainerError"
                    : "inputContactContainer"
                }
              >
                <div className="singleInputContactField">
                  <label>FULL NAME*</label>
                  <input
                    type="text"
                    id="customInput"
                    className="customContactInput"
                    placeholder="Enter your name"
                    value={name}
                    onChange={handleNameChange}
                  />
                </div>
                {nameError && (
                  <img
                    className="errorIconContact"
                    src={
                      "https://tbhwebsite.s3.me-south-1.amazonaws.com/errorIcon.webp"
                    }
                    alt="errorIconDesc"
                  />
                )}
              </div>
              {nameError && (
                <div className="errorStyleContact">{nameError}</div>
              )}
            </div>
            <div className={"inputContactSELECTContainer"}>
              <div className="singleInputContactField">
                <label>COUNTY CODE*</label>

                <select
                  className="singleSelectField"
                  value={selectedOption ? selectedOption.dial_code : ""}
                  onChange={handleSelectChange}
                >
                  {countriesData.map((option, index) => (
                    <option key={index} value={option.dial_code}>
                      {option.flag} {option.name} ({option.dial_code})
                    </option>
                  ))}
                </select>
              </div>
              <FontAwesomeIcon icon={faCaretDown} className="downArrowStyle" />
            </div>
            {/* <br /> */}
            <div className="aroundError">
              <div
                className={
                  phoneNumberError
                    ? "inputContactContainerError"
                    : "inputContactContainer"
                }
              >
                <div className="singleInputContactField">
                  <label>PHONE NUMBER*</label>

                  <input
                    type="text"
                    id="customInput"
                    className="customContactInput"
                    placeholder="Enter your phone number"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                  />
                </div>
                {phoneNumberError && (
                  <img
                    className="errorIconContact"
                    src={
                      "https://tbhwebsite.s3.me-south-1.amazonaws.com/errorIcon.webp"
                    }
                    alt="errorIconDesc"
                  />
                )}
              </div>
              {phoneNumberError && (
                <div className="errorStyleContact">{phoneNumberError}</div>
              )}
            </div>
            {/* <br /> */}
            <div className="aroundError">
              <div className={"inputContactContainer"}>
                <div className="singleInputContactField">
                  <label>E-mail*</label>
                  <input
                    type="text"
                    id="customInput"
                    className="customContactInput"
                    placeholder="Enter your e-mail"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </div>
                {emailError && (
                  <img
                    className="errorIconContact"
                    src={
                      "https://tbhwebsite.s3.me-south-1.amazonaws.com/errorIcon.webp"
                    }
                    alt="errorIconDesc"
                  />
                )}
              </div>{" "}
              {emailError && (
                <div className="errorStyleContact">{emailError}</div>
              )}
            </div>
            {/* <br /> */}
            <div className={"inputContactContainer"}>
              <div className="singleInputContactField">
                <label>MESSAGE</label>
                <textarea
                  rows="3"
                  type="text"
                  id="customInput"
                  className="customContactInput"
                  placeholder="Enter your message"
                  value={message}
                  onChange={handleMessageChange}
                />
              </div>
            </div>{" "}
            {/* <br /> */}
            <button
              className="btnSubmitContact"
              onClick={() => {
                handleSubmit({ preventDefault: () => {} });
                if (
                  name.length > 0 &&
                  phoneNumber.length > 0 &&
                  nameError.length === 0 &&
                  phoneNumberError.length === 0
                ) {
                  handleCaptchaAppear();
                }
              }}
            >
              SUBMIT
            </button>
          </div>
        </div>
        {/* ################## */}
        {/* right side section */}
        <div className="rightSideContact">
          <img
            onClick={() => {
              //
              // Open a new window
              window.open(
                " https://maps.app.goo.gl/SZcBqrXwYaXtdBku7",
                "_blank"
              );
            }}
            src={
              "https://tbhwebsite.s3.me-south-1.amazonaws.com/reachDestinationImage.webp"
            }
            alt="destination"
            className="styleImageDestination"
          />
        </div>
      </div>
    </>
  );
}

export default Contact;
