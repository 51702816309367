import React from "react";
import "./solutions.css";
import Carousel from "../customCarousel/carousel";
import { solutionsContentText } from "./solutionsData";
function Solutions() {
  const imagesList = [
    {
      src: "https://tbhwebsite.s3.me-south-1.amazonaws.com/imageOneSolutions.webp",
      alt: "image 1 Solutions",
    },
    {
      src: "https://tbhwebsite.s3.me-south-1.amazonaws.com/imageTwoSolutions.webp",
      alt: "image 2 Solutions",
    },
    {
      src: "https://tbhwebsite.s3.me-south-1.amazonaws.com/imageThreeSolutions.webp",
      alt: "image 3 Solutions",
    },
    {
      src: "https://tbhwebsite.s3.me-south-1.amazonaws.com/imageFourSolutions.webp",
      alt: "image 4 Solutions",
    },
  ];
  return (
    <div className="backDivSolutions">
      <div className="solutionsMainSection">
        {/* ################# */}
        {/* right section images Carousel */}
        <div className="leftSolutionsSection">
          <Carousel data={imagesList} />
        </div>
        {/* ============================= */}
        {/* left section images Carousel */}
        <div className="rightSolutionsSection">
          <h1 className="titleSolutions">
            {solutionsContentText.sectionTitle1}
          </h1>
          <h3 className="textSolutions">{solutionsContentText.sectionText}</h3>
          <div className="typesSolutions">
            {solutionsContentText.solutionsTypes.map((singleType, index) => (
              <div key={index} className="singleType">
                <div className="backDivIcon">
                  <img
                    src={singleType.icon}
                    alt={`icon ${index}`}
                    className="detailsIcon"
                  />
                </div>
                <div className="detailsOfType">
                  <h1 className="titleDetails">{singleType.title}</h1>
                  <h4 className="textDetails">{singleType.text}</h4>
                </div>
              </div>
            ))}
          </div>
          <button
            className="btnSolutions"
            onClick={() => {
              const element = document.getElementById("contactUsID");
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            {solutionsContentText.btnTextEng}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Solutions;
