import React from "react";
import "./footer.css";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  return (
    <div className="footerSectionStyle">
      <div className="footerContent">
        {/* %%%%%%%%%%%%%%% */}
        {/* left side div */}
        <div className="leftFooterSection">
          <img
            src={
              "https://tbhwebsite.s3.me-south-1.amazonaws.com/tbhLogoFooterWeb.webp"
            }
            className="styleLogoFooter"
            alt="TBH"
          />
        </div>
        {/* %%%%%%%%%%%%%%% */}
        {/* right side div */}
        <div className="rightFooterSection">
          <div className="contentContainer">
            <div className="addressContainer">
              <h1 className="titleFooterStyle">Address</h1>
              <div
                className="ADDRESS"
                onClick={() => {
                  //
                  // Open a new window
                  window.open(
                    " https://maps.app.goo.gl/SZcBqrXwYaXtdBku7",
                    "_blank"
                  );
                }}
              >
                <h3 className="textFooterStyle">St.79, Building 25, Maadi,</h3>
                <h3 className="textFooterStyle">Cairo.</h3>
              </div>
            </div>
            <div className="workingHoursContainer">
              <h1 className="titleFooterStyle">Daily Working Hours</h1>
              <h3 className="textFooterStyle">9 AM - 11 PM </h3>
            </div>{" "}
          </div>
          <div className="commWithTerms">
            <div className="socialMediaCommunity">
              <div className="communityContainer">
                <h1 className="communityStyle">JOIN OUR</h1>
                <h1 className="communityStyle">COMMUNITY!</h1>
              </div>
              <div className="socialMediaIcons">
                {" "}
                <img
                  onClick={() => {
                    window.open(
                      "https://www.facebook.com/thebusinesshub.space?mibextid=LQQJ4d",
                      "_blank"
                    );
                  }}
                  className="socialIconFooter"
                  src={
                    "https://tbhwebsite.s3.me-south-1.amazonaws.com/facbookIconFooter.webp"
                  }
                  alt="facebook"
                />
                <img
                  onClick={() => {
                    window.open(
                      "https://instagram.com/thebusinesshub.space",
                      "_blank"
                    );
                  }}
                  className="socialIconFooter"
                  src={
                    "https://tbhwebsite.s3.me-south-1.amazonaws.com/instgramIconFooter.webp"
                  }
                  alt="instagram"
                />
              </div>
            </div>
            <h2
              onClick={() => {
                navigate("/Policy");
                window.scrollTo({
                  top: 0,
                  behavior: "smooth", // Optional: Scroll with smooth animation
                });
              }}
              className="termsStyle"
            >
              Terms & Conditions
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
